import { Filter, List, ResourceContextProvider } from 'react-admin'

import Scheduler from '../../components/Scheduler'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import HubsFilter from '../hubs/filter'
import OrganisationsFilter from '../organisations/filter'
import { useGetOrganisations } from '../organisations/hooks'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehiclesConfig from '../vehicles/config'

export const TIMELINE_KEY = 'timeline'

const TimelineFilters = (props) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  return (
    <Filter {...props}>
      <OrganisationsFilter alwaysOn />
      <HubsFilter alwaysOn />
      {hasReadForUsers && <UserReferenceInput alwaysOn validate={null} />}
    </Filter>
  )
}

export const VehiclesTimelinePage = () => {
  const organisations = useGetOrganisations({ pagination: { page: 1, perPage: 1 } })
  const firstOrganisationId = organisations.data[0]?.id
  return (
    <ResourceContextProvider value={vehiclesConfig.name}>
      <List
        actions={null}
        empty={false}
        // Do not apply hub & user filters on vehicles list but keep it above in the filters for use in the scheduler
        filter={{ hub_id: undefined, user_id: undefined }}
        filterDefaultValues={{ ...vehiclesConfig.options.defaultFilterValues, organisation_id: firstOrganisationId }}
        filters={<TimelineFilters />}
        pagination={false}
        storeKey={TIMELINE_KEY}
        sx={{ '& .RaList-main': { maxWidth: '100%' } }}
        title="mymove.scheduler.name"
      >
        <Scheduler isFullList />
      </List>
    </ResourceContextProvider>
  )
}

VehiclesTimelinePage.path = '/' + TIMELINE_KEY
