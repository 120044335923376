import PreviousIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import NextIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { Avatar, Box, Button, ButtonGroup, CardHeader, Dialog, Grid, Typography } from '@mui/material'
import { get, isEmpty, keyBy, orderBy, uniq } from 'lodash'
import { DateTime } from 'luxon'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Confirm,
  useCreatePath,
  useDataProvider,
  useDelete,
  useGetList,
  useGetMany,
  useGetRecordRepresentation,
  useListContext,
  useNotify,
} from 'react-admin'

import { getBookingConsumedIcon } from './BookingConsumedField'
import LargeTooltip from './LargeTooltip'
import { getVehicleName } from './VehicleNameField'
import { useAccounts } from '../api/accountsProvider'
import {
  BOOKING_ACTION_CANCEL,
  BOOKING_ACTION_END,
  BOOKING_ACTIONS_SCHEDULER_ICONS,
  BOOKING_BILLING_TYPES_ICONS,
} from '../config/bookings'
import {
  SYSTEM_PERMISSION_ACTIONS,
  SYSTEM_PERMISSION_CREATE,
  SYSTEM_PERMISSION_DELETE,
  SYSTEM_PERMISSION_READ,
  SYSTEM_PERMISSION_UPDATE,
} from '../config/permissions'
import { STATUS_CODES, STATUS_SEVERITY_CRITICAL } from '../config/statuses'
import { useCommonStyles } from '../config/theme'
import { VEHICLE_TYPE_BIKE, VEHICLE_TYPE_KICK_SCOOTER } from '../config/vehicles'
import {
  VEHICLE_UNAVAILABILITY_TYPE_OTHER,
  VEHICLE_UNAVAILABILITY_TYPES,
  VEHICLE_UNAVAILABILITY_TYPES_COLORS,
} from '../config/vehicleUnavailabilities'
import { isBookingEditable } from '../domain/bookings'
import { useResourcePermissions } from '../domain/permissions'
import { getResourceByName } from '../resources'
import bookingDetailsConfig from '../resources/bookingDetails/config'
import bookingsConfig from '../resources/bookings/config'
import { useCreateBooking, useEditBooking } from '../resources/bookings/form'
import { useCanEditBookings } from '../resources/bookings/hooks'
import { CardTitle, getResourceSuccessMessage } from '../resources/common'
import { useRunResourceAction } from '../resources/common/hooks'
import { useListStyles } from '../resources/common/list'
import { useGetHubsListForOrganisation } from '../resources/hubs/hooks'
import usersConfig from '../resources/users/config'
import vehiclesConfig from '../resources/vehicles/config'
import vehicleUnavailabilitiesConfig from '../resources/vehicleUnavailabilities/config'
import { useCreateVehicleUnavailability, useEditVehicleUnavailability } from '../resources/vehicleUnavailabilities/form'
import { toSentence } from '../utils'
import { formatDateTime, getDateBoundsForPeriod, getRoundedNow, getRoundedNowJS } from '../utils/dates'
import { getRandomColorFromString } from '../utils/theme'
import BryntumScheduler from '../vendor/bryntum/BryntumScheduler'
import {
  defaultPresets,
  hourAndDayCustomName,
  weekAndDayLetterCustomName,
  weekAndMonthCustomName,
} from '../vendor/bryntum/presets'

const getDatesFromEvent = (event) => {
  const isBooking = event.resource === bookingsConfig.name
  const startDate = new Date(isBooking ? event.effective_started_on : event.started_on)
  const endDate = new Date(isBooking ? event.effective_ended_on : event.ended_on)
  return { startDate, endDate }
}

const getActionValues = (action, id) => {
  const actionName = action.toLowerCase()
  const actionTitle = `resources.bookings.actions.${actionName}.label`
  return { action, actionName, actionTitle, id }
}

const Scheduler = ({ disabledInputsSources, isFullList = false }) => {
  const { data: rawResources, filterValues, resource: groupResource } = useListContext()
  const { data: hubs } = useGetHubsListForOrganisation(filterValues.organisation_id)

  const createPath = useCreatePath()
  const notify = useNotify()
  const translate = useTranslate()

  const { currentAccount } = useAccounts()
  const commonClasses = useCommonStyles()
  const dataProvider = useDataProvider()
  const listClasses = useListStyles()
  const schedulerRef = useRef()

  // Dates
  const weekViewBounds = { period: 'week', startOffset: { weeks: 2 }, endOffset: { weeks: 4 } }
  const defaultDates = getDateBoundsForPeriod(weekViewBounds)
  const [dates, setDates] = useState(defaultDates)

  // Group resource & related resource
  const isGroupResourceVehicles = groupResource === vehiclesConfig.name
  const relatedResource = isGroupResourceVehicles ? usersConfig.name : vehiclesConfig.name
  const relatedConfig = getResourceByName(relatedResource)
  const groupConfig = getResourceByName(groupResource)
  const relatedRecordRepresentation = useGetRecordRepresentation(relatedConfig.name)
  const groupRecordRepresentation = useGetRecordRepresentation(groupConfig.name)

  // Permissions

  const [hasReadForBookings, hasCreateForBookings, hasActionsForBookings] = useResourcePermissions(
    bookingsConfig.name,
    SYSTEM_PERMISSION_READ,
    SYSTEM_PERMISSION_CREATE,
    SYSTEM_PERMISSION_ACTIONS,
  )
  const canEditBookings = useCanEditBookings()
  const [hasReadForRelatedResource] = useResourcePermissions(relatedResource, SYSTEM_PERMISSION_READ)
  const [
    hasReadForVehicleUnavailabilities,
    hasCreateForVehicleUnavailabilities,
    hasEditForVehicleUnavailabilities,
    hasDeleteForVehicleUnavailabilities,
  ] = useResourcePermissions(
    vehicleUnavailabilitiesConfig.name,
    SYSTEM_PERMISSION_READ,
    SYSTEM_PERMISSION_CREATE,
    SYSTEM_PERMISSION_UPDATE,
    SYSTEM_PERMISSION_DELETE,
  )

  const canDeleteResource = useCallback(
    (resource) => {
      if (resource === bookingsConfig.name) return false
      if (resource === vehicleUnavailabilitiesConfig.name) return hasDeleteForVehicleUnavailabilities
      return false
    },
    [hasDeleteForVehicleUnavailabilities],
  )
  const canEditResource = useCallback(
    (data) => {
      if (data.resource === bookingsConfig.name) return canEditBookings && isBookingEditable(data)
      if (data.resource === vehicleUnavailabilitiesConfig.name) return hasEditForVehicleUnavailabilities
      return false
    },
    [canEditBookings, hasEditForVehicleUnavailabilities],
  )
  const canReadResource = useCallback(
    (resource) => {
      if (resource === bookingsConfig.name) return hasReadForBookings
      if (resource === vehicleUnavailabilitiesConfig.name) return hasReadForVehicleUnavailabilities
      return false
    },
    [hasReadForBookings, hasReadForVehicleUnavailabilities],
  )
  const canTriggerActionsOnResource = useCallback(
    (resource) => {
      if (resource === bookingsConfig.name) return hasActionsForBookings
      if (resource === vehicleUnavailabilitiesConfig.name) return false
      return false
    },
    [hasActionsForBookings],
  )

  // Bookings & vehicle unavailabilities

  const pagination = { page: 1, perPage: 99999 }
  const commonFilter = {
    organisation_id: filterValues.organisation_id,
    hub_id: filterValues.hub_id,
    vehicle_id: isGroupResourceVehicles && !isFullList ? rawResources?.[0]?.id : undefined,
    timeline_start: dates.startDate,
    timeline_end: dates.endDate,
  }
  const userId = !isGroupResourceVehicles && !isFullList ? rawResources?.[0]?.id : filterValues.user_id
  const bookingsFilter = { user_id: userId, ...commonFilter, ...bookingsConfig.options.defaultFilterValues }

  let { data: bookingsData } = useGetList(
    bookingsConfig.name,
    { pagination, filter: bookingsFilter },
    { enabled: hasReadForBookings && !isEmpty(rawResources) },
  )
  bookingsData = bookingsData || []
  const bookings = bookingsData.map((b) => ({ ...b, resource: bookingsConfig.name }))

  let { data: vehicleUnavailabilitiesData } = useGetList(
    vehicleUnavailabilitiesConfig.name,
    { pagination, filter: commonFilter },
    { enabled: hasReadForVehicleUnavailabilities && isGroupResourceVehicles && !isEmpty(rawResources) },
  )
  vehicleUnavailabilitiesData = vehicleUnavailabilitiesData || []
  const vehicleUnavailabilities = vehicleUnavailabilitiesData.map((u) => ({
    ...u,
    resource: vehicleUnavailabilitiesConfig.name,
  }))

  // Related resource data (users or vehicles)

  const relatedIds = useMemo(
    () => uniq(bookings.map((b) => b[relatedConfig.options.referenceKey])),
    [JSON.stringify(bookings)], // eslint-disable-line react-hooks/exhaustive-deps
  )
  const { data: relatedItems, isLoading: isLoadingRelatedItems } = useGetMany(
    relatedResource,
    { ids: relatedIds },
    { enabled: hasReadForRelatedResource },
  )
  const relatedCacheRef = useRef({})
  const relatedCache = useMemo(() => {
    relatedCacheRef.current = { ...relatedCacheRef.current, ...keyBy(relatedItems, 'id') }
    return relatedCacheRef.current
  }, [JSON.stringify(relatedItems)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Main resource data (vehicles or users)

  const resources = useMemo(() => {
    const fullResources = (rawResources || [])
      .filter((r) => (filterValues.hub_id ? r.hub_id === filterValues.hub_id : true))
      .map((r) => ({
        ...r,
        ...(isGroupResourceVehicles && hubs && { hub: hubs.find((h) => h.id === r.hub_id)?.name }),
        name: groupRecordRepresentation(r),
        resource: groupResource,
      }))
    return orderBy(fullResources, ['type', 'blueprint_set', 'name'], ['desc', 'asc', 'asc'])
  }, [filterValues.hub_id, JSON.stringify(rawResources), JSON.stringify(hubs)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Resource time ranges (critical status & out of lease) & events (bookings & vehicle unavailabilities)

  const resourceTimeRanges = useMemo(() => {
    const ranges = []
    if (!schedulerRef.current) return ranges
    const now = getRoundedNowJS()
    resources.forEach((r) => {
      if (r.status?.severity === STATUS_SEVERITY_CRITICAL) {
        const codes = r.status.codes.map((c) => `"${translate(STATUS_CODES[c]).toLowerCase()}"`)
        const name =
          schedulerRef.current.schedulerInstance.viewPreset.id !== weekAndMonthCustomName
            ? `${translate('resources.vehicles.criticalOperationalStatus')} ${toSentence(codes)}`
            : ''
        const criticalStyle =
          'color: #1F1F1F; background: repeating-linear-gradient(315deg, #F5BBB7, #F5BBB7 5px, #E8EDEC 5px, #E8EDEC 10px);'
        ranges.push({ duration: 7, name, resourceId: r.id, startDate: now, style: criticalStyle })
      }
      if (r.ended_on) {
        const name = translate('resources.vehicles.outOfCurrentLease')
        const outOfLeaseStyle =
          'color: #474747; background: repeating-linear-gradient(315deg, #F0F0F0, #F0F0F0 5px, #D6D6D6 5px, #D6D6D6 10px);'
        ranges.push({ duration: 9999, name, resourceId: r.id, startDate: new Date(r.ended_on), style: outOfLeaseStyle })
      }
    })
    return ranges
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(resources), schedulerRef?.current?.schedulerInstance?.viewPreset?.id])

  const events = useMemo(
    () => {
      const includedEvents = isLoadingRelatedItems ? vehicleUnavailabilities : [...bookings, ...vehicleUnavailabilities]
      return includedEvents.map((event) => {
        const refKey = event[relatedConfig.options.referenceKey]
        const isBooking = event.resource === bookingsConfig.name
        const { startDate, endDate } = getDatesFromEvent(event)
        const name = isBooking
          ? relatedRecordRepresentation(relatedCache[refKey]).trim() || translate('resources.bookings.name', 1)
          : event.type === VEHICLE_UNAVAILABILITY_TYPE_OTHER
          ? translate('resources.maintenances.otherUnavailability')
          : translate(VEHICLE_UNAVAILABILITY_TYPES[event.type])
        const eventColor = isBooking
          ? getRandomColorFromString(refKey)
          : VEHICLE_UNAVAILABILITY_TYPES_COLORS[event.type]
        const icons = isBooking
          ? [get(BOOKING_BILLING_TYPES_ICONS, event.billing_type), getBookingConsumedIcon(event)]
          : [vehicleUnavailabilitiesConfig.icon]
        const link = createPath({ resource: event.resource, id: event.id, type: 'show' })
        const resourceId = event[groupConfig.options.referenceKey]
        const item = { ...event, name, startDate, endDate, eventColor, link, icons, resourceId }
        return item
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingRelatedItems, JSON.stringify({ bookings, vehicleUnavailabilities, relatedCache })],
  )

  // Bookings & vehicle unavailabilities creation

  const [bookingOrVehicleUnavailabilityCreationDialogState, setBookingOrVehicleUnavailabilityCreationDialogState] =
    useState({ isOpen: false, values: {} })

  const closeBookingOrVehicleUnavailabilityCreationDialogIfNeeded = useCallback(() => {
    if (bookingOrVehicleUnavailabilityCreationDialogState.isOpen) {
      setBookingOrVehicleUnavailabilityCreationDialogState({ isOpen: false, values: {} })
    }
  }, [bookingOrVehicleUnavailabilityCreationDialogState.isOpen])

  const [openCreateBookingDialog, createBookingDialog] = useCreateBooking({ disabledInputsSources })
  const [openCreateVehicleUnavailabilityDialog, createVehicleUnavailabilityDialog] = useCreateVehicleUnavailability({
    disabledInputsSources,
  })

  const handleOpenCreateBookingDialog = useCallback(
    ({ values = {} }) => {
      closeBookingOrVehicleUnavailabilityCreationDialogIfNeeded()
      openCreateBookingDialog({
        ...bookingOrVehicleUnavailabilityCreationDialogState.values,
        ...values,
        user_id: userId,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, openCreateBookingDialog, JSON.stringify(bookingOrVehicleUnavailabilityCreationDialogState.values)],
  )

  const handleOpenCreateVehicleUnavailabilityDialog = useCallback(
    ({ values = {} }) => {
      closeBookingOrVehicleUnavailabilityCreationDialogIfNeeded()
      openCreateVehicleUnavailabilityDialog({
        ...bookingOrVehicleUnavailabilityCreationDialogState.values,
        ...values,
        started_on:
          values.start_scheduled_on || bookingOrVehicleUnavailabilityCreationDialogState.values.start_scheduled_on,
        ended_on: values.end_scheduled_on || bookingOrVehicleUnavailabilityCreationDialogState.values.end_scheduled_on,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openCreateVehicleUnavailabilityDialog, JSON.stringify(bookingOrVehicleUnavailabilityCreationDialogState.values)],
  )

  const onBookingOrVehicleUnavailabilityCreate = useCallback(
    (values) => {
      const shouldOpenBookingOrVehicleUnavailabilityCreationDialog =
        isGroupResourceVehicles && hasCreateForBookings && hasCreateForVehicleUnavailabilities
      const shouldOpenCreateBookingDialog =
        hasCreateForBookings && (!isGroupResourceVehicles || !hasCreateForVehicleUnavailabilities)
      const shouldOpenCreateVehicleUnavailabilityDialog = isGroupResourceVehicles && hasCreateForVehicleUnavailabilities

      if (shouldOpenBookingOrVehicleUnavailabilityCreationDialog) {
        setBookingOrVehicleUnavailabilityCreationDialogState({ isOpen: true, values })
        return
      }
      if (shouldOpenCreateBookingDialog) {
        handleOpenCreateBookingDialog({ values })
        return
      }
      if (shouldOpenCreateVehicleUnavailabilityDialog) {
        handleOpenCreateVehicleUnavailabilityDialog({ values })
      }
    },
    [
      isGroupResourceVehicles,
      hasCreateForBookings,
      hasCreateForVehicleUnavailabilities,
      handleOpenCreateBookingDialog,
      handleOpenCreateVehicleUnavailabilityDialog,
    ],
  )

  // Bookings & vehicle unavailabilities edition

  const [openEditBookingDialog, editBookingDialog] = useEditBooking()
  const [openEditVehicleUnavailabilityDialog, editVehicleUnavailabilityDialog] = useEditVehicleUnavailability()

  // Bookings & vehicle unavailabilities actions

  const [bookingsActionsConfirmState, setBookingsActionsConfirmState] = useState({ isOpen: false, values: {} })
  const closeBookingsActionsConfirm = () => {
    setBookingsActionsConfirmState((prevState) => ({ ...prevState, isOpen: false }))
    setTimeout(() => setBookingsActionsConfirmState((prevState) => ({ ...prevState, values: {} })), 200)
  }
  const [runBookingAction, isRunningBookingAction] = useRunResourceAction({
    ...bookingsActionsConfirmState.values,
    onSuccess: closeBookingsActionsConfirm,
    resource: bookingsConfig.name,
  })

  // Bookings & vehicle unavailabilities deletion

  const [deleteVehicleUnavailabilityConfirmState, setDeleteVehicleUnavailabilityConfirmState] = useState({
    isOpen: false,
    id: null,
  })
  const closeDeleteVehicleUnavailabilityConfirm = useCallback(
    () => setDeleteVehicleUnavailabilityConfirmState({ isOpen: false, id: null }),
    [],
  )
  const [deleteVehicleUnavailability, { isPending: isDeletingVehicleUnavailability }] = useDelete(
    vehicleUnavailabilitiesConfig.name,
    { id: deleteVehicleUnavailabilityConfirmState.id },
    {
      onError: (error) => notify(error.message, { type: 'error' }),
      onSuccess: () => {
        closeDeleteVehicleUnavailabilityConfirm()
        notify(getResourceSuccessMessage(vehicleUnavailabilitiesConfig.name, 'actions', 'delete'))
      },
    },
  )

  const onBeforeEventDelete = useCallback(
    async ({ eventRecords }) => {
      const { id, resource } = eventRecords[0].data
      const canDelete = canDeleteResource(resource)
      const canTriggerActions = canTriggerActionsOnResource(resource)
      if (resource === vehicleUnavailabilitiesConfig.name && canDelete) {
        setDeleteVehicleUnavailabilityConfirmState({ isOpen: true, id })
      } else if (resource === bookingsConfig.name && canTriggerActions) {
        await dataProvider.getOne(bookingDetailsConfig.name, { id }).then(({ data }) => {
          if (!isEmpty(data?.allowed_actions)) {
            if (data.allowed_actions.includes(BOOKING_ACTION_CANCEL)) {
              const values = getActionValues(BOOKING_ACTION_CANCEL, id)
              setBookingsActionsConfirmState({ isOpen: true, values })
            } else if (data.allowed_actions.includes(BOOKING_ACTION_END)) {
              const values = getActionValues(BOOKING_ACTION_END, id)
              setBookingsActionsConfirmState({ isOpen: true, values })
            }
          }
        })
      }
      return false
    },
    [canDeleteResource, canTriggerActionsOnResource], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Cell menu + event drag, menu, renderer, resize, tooltip & store

  const showResource = useRef() // we use a ref here to retrieve the updated value of the currentAccount object
  showResource.current = (eventRecord) => {
    if (!currentAccount) return
    const { resource, id } = eventRecord.data
    const link = createPath({ resource, id, type: 'show' })
    window.open('/' + currentAccount.slug + link, '_blank')
  }

  const cellMenu = useMemo(
    () => ({
      items: { copy: false, cut: false, paste: false, removeRow: false },
      processItems: ({ items, record }) => {
        if (record.data.resource === vehiclesConfig.name) {
          items.showVehicle = {
            text: translate('ra.action.show') + ' ' + translate('resources.vehicles.name', 1).toLowerCase(),
            icon: 'b-fa b-fa-arrow-up-right-from-square',
            onItem: () => showResource.current(record),
          }
        }
      },
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [isMouseDownWithCmdOrCtrlKey, setIsMouseDownWithCmdOrCtrlKey] = useState(false)
  useEffect(() => {
    const handleMouseDown = (event) => {
      if (event.metaKey || event.ctrlKey) setIsMouseDownWithCmdOrCtrlKey(true)
    }
    const handleMouseUp = () => setIsMouseDownWithCmdOrCtrlKey(false)
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  const eventDrag = useMemo(
    () => ({
      constrainDragToTimeSlot: isMouseDownWithCmdOrCtrlKey,
      copyKey: '', // disable duplicate of events with Cmd or Ctrl key pressed
      showExactDropPosition: true,
    }),
    [isMouseDownWithCmdOrCtrlKey],
  )

  const eventMenu = useMemo(
    () => ({
      items: { copyEvent: false, cutEvent: false, renameSegment: false, splitEvent: false, unassignEvent: false },
      processItems: async ({ eventRecord, items }) => {
        // Do not display menu when clicking on an event with Cmd or Ctrl key pressed
        if (isMouseDownWithCmdOrCtrlKey) return false

        // Handle actions when right-clicking on an event
        const { id, resource } = eventRecord.data
        const itemName = translate(`resources.${resource}.name`, 1).toLowerCase()
        const canDelete = canDeleteResource(resource)
        const canEdit = canEditResource(eventRecord.data)
        const canRead = canReadResource(resource)
        const canTriggerActions = canTriggerActionsOnResource(resource)

        if (canTriggerActions && resource === bookingsConfig.name) {
          await dataProvider
            .getOne(bookingDetailsConfig.name, { id })
            .then(({ data }) => {
              if (!isEmpty(data?.allowed_actions)) {
                data.allowed_actions.forEach((action) => {
                  const values = getActionValues(action, id)
                  const eventName = `${values.actionName}Event`
                  items[eventName] = {
                    icon: BOOKING_ACTIONS_SCHEDULER_ICONS[action],
                    text: translate(values.actionTitle),
                    onItem: () => setBookingsActionsConfirmState({ isOpen: true, values }),
                  }
                })
              }
            })
            .catch((_) => {})
        }

        if (canDelete) {
          items.deleteEvent = {
            text: translate('ra.action.delete') + ' ' + itemName,
            icon: 'b-fa b-fa-trash',
            onItem: ({ eventRecord: record }) => {
              const { id, resource } = record.data
              if (resource === vehicleUnavailabilitiesConfig.name) {
                setDeleteVehicleUnavailabilityConfirmState({ isOpen: true, id })
              }
            },
          }
        } else {
          items.deleteEvent = false
        }

        if (canEdit) {
          items.editEvent.text = translate('ra.action.edit') + ' ' + itemName
          items.editEvent.icon = 'b-fa b-fa-pen'
        } else {
          items.editEvent = false
        }

        if (canRead) {
          items.seeEvent = {
            text: translate('ra.action.show') + ' ' + itemName,
            icon: 'b-fa b-fa-arrow-up-right-from-square',
            onItem: ({ eventRecord: record }) => showResource.current(record),
          }
        } else {
          items.seeEvent = false
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canDeleteResource, canEditResource, canReadResource, canTriggerActionsOnResource, isMouseDownWithCmdOrCtrlKey],
  )

  const eventRenderer = useCallback(({ eventRecord }) => {
    return `
        <section class="b-sch-event-${eventRecord.get('id')} b-sch-event-body">
          <div class="b-sch-event-body-header">${eventRecord.name}</div>
          <div class="b-sch-event-body-content">${
            formatDateTime(eventRecord.startDate) + ' → ' + formatDateTime(eventRecord.endDate)
          }</div>
        </section>
      `
  }, [])

  const eventResize = useMemo(
    () => ({
      showExactResizePosition: true,
      validatorFn: ({ endDate, eventRecord, mainContext, startDate }) => {
        const { resource, startDate: originalStartDate } = eventRecord.data
        if (resource === bookingsConfig.name) {
          const now = DateTime.local()
          // Prevent resizing a finished booking
          if (endDate < now) {
            return false
          }
          // Prevent resizing a current booking start date
          if (mainContext.edge === 'left' && originalStartDate < now) {
            return false
          }
          // Prevent resizing a future booking into the past
          if (mainContext.edge === 'left' && startDate < now) {
            return false
          }
        }
        return true
      },
    }),
    [],
  )

  const eventTooltip = useMemo(
    () => ({
      template: ({ eventRecord, resourceRecord }) => {
        const isBooking = eventRecord.get('resource') === bookingsConfig.name
        const icons = eventRecord.get('icons')
        return (
          <>
            <div class="b-sch-event-tooltip-icon">
              {icons.map((Icon) => (
                <Icon key={Icon.displayName} />
              ))}
            </div>
            <div class="b-sch-event-tooltip-header">
              <div class="b-sch-event-tooltip-title">{eventRecord.get('name')}</div>
              <div class="b-sch-event-tooltip-subtitle">{resourceRecord.get('name')}</div>
            </div>
            <dl>
              {isBooking && (
                <>
                  <dt>{translate('mymove.scheduler.scheduledOn')}</dt>
                  <dd>
                    {formatDateTime(eventRecord.get('start_scheduled_on'))} →{' '}
                    {formatDateTime(eventRecord.get('end_scheduled_on'))}
                  </dd>
                </>
              )}
              <dt>{translate(isBooking ? 'mymove.scheduler.usedOn' : 'mymove.scheduler.period')}</dt>
              <dd>
                {formatDateTime(eventRecord.get('started_on'))} → {formatDateTime(eventRecord.get('ended_on'))}
              </dd>
            </dl>
          </>
        )
      },
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const eventStore = useMemo(() => {
    return {
      onUpdate: ({ record, changes: { resourceId, startDate, endDate, failedUpdate, updated_on: updatedOn } }) => {
        if (failedUpdate?.value === true) {
          record.set('failedUpdate', false, true)
          return true
        }
        if (!Boolean(resourceId) && !Boolean(startDate) && !Boolean(endDate)) return true
        // If updated_on was changed, it means the record was updated by the system (e.g. booking end) and we don't want to go further
        if (Boolean(updatedOn)) return true

        const { resource, id } = record.data
        const isBooking = resource === bookingsConfig.name
        const startKey = isBooking ? 'start_scheduled_on' : 'started_on'
        const endKey = isBooking ? 'end_scheduled_on' : 'ended_on'

        const attributes = isBooking
          ? ['justification', 'billing_type', 'additional_driver_user_ids']
          : ['organisation_id', 'hub_id']

        const updatedValues = {
          ...Object.fromEntries([...attributes, 'vehicle_id'].map((attr) => [attr, record.data[attr]])),
          [startKey]: startDate?.value?.toISOString() || record.data[startKey],
          [endKey]: endDate?.value?.toISOString() || record.data[endKey],
          ...(resourceId?.value && { vehicle_id: resourceId.value }),
        }
        record.set(updatedValues, null, true)
        dataProvider.update(resource, { id, data: updatedValues }).catch((error) => {
          record.set({
            ...(startDate && { startDate: startDate.oldValue, [startKey]: startDate.oldValue.toISOString() }),
            ...(endDate && { endDate: endDate.oldValue, [endKey]: endDate.oldValue.toISOString() }),
            ...(resourceId && { vehicle_id: resourceId.oldValue, resourceId: resourceId.oldValue }),
            failedUpdate: true,
          })
          notify(error.message, { type: 'error' })
        })
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Listeners & columns

  const listeners = useMemo(() => {
    return {
      beforeCopy: () => false,
      beforeDragCreateFinalize: ({ context, resourceRecord }) => {
        context.finalize(false) // prevent default behavior in any case
        const { endDate, startDate } = context
        // Prevent creating anything before the vehicle is available
        if (startDate < new Date(resourceRecord.data.started_on)) {
          return
        }
        // Prevent creating anything after the vehicle becomes unavailable
        if (
          resourceRecord.data.ended_on &&
          (startDate > new Date(resourceRecord.data.ended_on) || endDate > new Date(resourceRecord.data.ended_on))
        ) {
          return
        }
        // Prevent creating anything ending in the past
        // Creating a resource with a start date in the past is allowed but start date will be set to now
        const now = DateTime.local()
        if (endDate < now) {
          return
        }
        onBookingOrVehicleUnavailabilityCreate({
          organisation_id: isGroupResourceVehicles ? resourceRecord.get('organisation_id') : null,
          hub_id: isGroupResourceVehicles ? resourceRecord.get('hub_id') : null,
          vehicle_id: isGroupResourceVehicles ? resourceRecord.get('id') : null,
          start_scheduled_on: Math.max(startDate, now),
          end_scheduled_on: endDate,
        })
      },
      beforeEventDrag: ({ eventRecord }) => {
        const { data, startDate } = eventRecord
        if (!canEditResource(data)) {
          return false
        }
        // Prevent moving a booking that has already started
        if (data.resource === bookingsConfig.name && startDate < DateTime.local()) {
          return false
        }
        return true
      },
      beforeEventDropFinalize: ({ context }) => {
        const { eventRecords, newResource, resourceRecord, startDate } = context
        const { resource } = eventRecords[0].data
        // Prevent a booking from being moved in the past
        if (resource === bookingsConfig.name && startDate < DateTime.local()) {
          return context.finalize(false)
        }
        // Prevent updating the vehicle of a vehicle unavailability
        if (resource === vehicleUnavailabilitiesConfig.name && resourceRecord.data.id !== newResource.data.id) {
          return context.finalize(false)
        }
        const result = window.confirm(translate('mymove.scheduler.moveElementConfirmation'))
        context.finalize(result)
      },
      beforeEventEdit: ({ eventRecord }) => {
        if (!eventRecord.isPhantom) {
          const { resource } = eventRecord.data
          const canEdit = canEditResource(eventRecord.data)
          if (canEdit) {
            if (resource === bookingsConfig.name) {
              openEditBookingDialog(eventRecord.data)
            } else if (resource === vehicleUnavailabilitiesConfig.name) {
              openEditVehicleUnavailabilityDialog(eventRecord.data)
            }
          }
        }
        return false
      },
      beforeEventResize: ({ eventRecord }) => {
        const { data, endDate } = eventRecord
        if (!canEditResource(data)) {
          return false
        }
        // Prevent resizing a finished booking
        if (data.resource === bookingsConfig.name && endDate < DateTime.local()) {
          return false
        }
        return true
      },
      beforePaste: () => false,
      timeAxisChange: ({ config: { startDate, endDate } }) => {
        setDates({ startDate, endDate })
      },
    }
  }, [canEditResource, onBookingOrVehicleUnavailabilityCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(() => {
    return [
      {
        enableHeaderContextMenu: false,
        field: 'name',
        sortable: false,
        width: 240,
        renderer: (data) => {
          const { name, picture, type } = data.record
          const imgHeight = [VEHICLE_TYPE_BIKE, VEHICLE_TYPE_KICK_SCOOTER].includes(type) ? 45 : 60
          return (
            <CardHeader
              style={{ padding: 0 }}
              avatar={
                picture ? (
                  <Box
                    width={60}
                    height={imgHeight}
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img style={{ maxWidth: '100%', maxHeight: '100%' }} alt={name} src={picture} />
                  </Box>
                ) : (
                  <Avatar alt={name}>{(name || ' ').charAt(0)}</Avatar>
                )
              }
              title={isGroupResourceVehicles ? getVehicleName(data.record) : groupRecordRepresentation(data.record)}
              subheader={
                isGroupResourceVehicles ? (
                  <Typography component="span" variant="body2" color="grey.700">
                    {data.record.get('designation')}
                  </Typography>
                ) : null
              }
            />
          )
        },
      },
    ]
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // We prevent the default context menu from appearing when right-clicking anywhere on the scheduler
  const onSchedulerContainerContextMenu = useCallback((e) => e.preventDefault(), [])

  // Zoom & navigation

  const scrollToNow = useCallback(() => {
    if (!schedulerRef.current) return
    schedulerRef.current.schedulerInstance.scrollToNow({ animate: true, block: 'center' })
  }, [])

  const zoomToPresetWithBounds = useCallback((preset, boundsSettings, centerDate) => {
    if (!schedulerRef.current) return
    if (preset === schedulerRef.current.schedulerInstance.viewPreset.id) return
    const newCenterDate = centerDate ?? DateTime.fromJSDate(schedulerRef.current.schedulerInstance.viewportCenterDate)
    const bounds = getDateBoundsForPeriod({ ...boundsSettings, referenceDate: newCenterDate })
    schedulerRef.current.schedulerInstance.zoomTo({ preset, centerDate: newCenterDate, ...bounds })
  }, [])

  const zoomToDayView = useCallback(() => {
    zoomToPresetWithBounds(hourAndDayCustomName, { period: 'day', startOffset: { days: 2 }, endOffset: { days: 4 } })
  }, [zoomToPresetWithBounds])

  const zoomToWeekView = useCallback(
    (params) => {
      zoomToPresetWithBounds(weekAndDayLetterCustomName, weekViewBounds, params?.centerDate)
    },
    [zoomToPresetWithBounds], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const zoomToMonthView = useCallback(() => {
    zoomToPresetWithBounds(weekAndMonthCustomName, {
      period: 'day',
      startOffset: { months: 2 },
      endOffset: { months: 2 },
    })
  }, [zoomToPresetWithBounds])

  const zoomOut = useCallback(() => {
    if (!schedulerRef.current) return
    const { id: preset } = schedulerRef.current.schedulerInstance.viewPreset
    if (preset === hourAndDayCustomName) {
      zoomToWeekView()
    } else if (preset === weekAndDayLetterCustomName) {
      zoomToMonthView()
    }
  }, [zoomToWeekView, zoomToMonthView])

  const zoomIn = useCallback(() => {
    if (!schedulerRef.current) return
    const { id: preset } = schedulerRef.current.schedulerInstance.viewPreset
    if (preset === weekAndMonthCustomName) {
      zoomToWeekView()
    } else if (preset === weekAndDayLetterCustomName) {
      zoomToDayView()
    }
  }, [zoomToWeekView, zoomToDayView])

  useEffect(() => {
    // As built-in visibleDate and scrollToNow are not exactly centering the current time, we use zoomToWeekView on mount
    const now = getRoundedNow()
    zoomToWeekView({ centerDate: now })
  }, [schedulerRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  // Loading & missing data messages

  const CommonCardHeader = ({ withClassName = true }) => (
    <CardHeader
      title={<CardTitle text="mymove.scheduler.name" />}
      className={withClassName ? listClasses.titleContainer : null}
    />
  )

  const MissingDataMessage = ({ text, withDots = false }) => (
    <>
      <CommonCardHeader />
      <Typography variant="body2" sx={{ margin: 2 }}>
        {translate(text)}
        {withDots && '...'}
      </Typography>
    </>
  )

  if (isFullList && !filterValues.organisation_id) {
    return <MissingDataMessage text="mymove.scheduler.disabledMessage" />
  }

  const shouldDisplayLoadingMessage =
    !rawResources ||
    (isFullList && !hubs) ||
    (!isEmpty(hubs) && !isEmpty(rawResources) && hubs.every((h) => h.id !== rawResources[0].hub_id))
  if (shouldDisplayLoadingMessage) {
    return <MissingDataMessage text="ra.page.loading" withDots />
  }

  if (Array.isArray(rawResources) && isEmpty(rawResources)) {
    return <MissingDataMessage text="mymove.noOption" />
  }

  return (
    <>
      <Box className={commonClasses.borderBottom} display="flex" alignItems="center" justifyContent="space-between">
        <CommonCardHeader withClassName={false} />
        <Box p={2} display="flex">
          <ButtonGroup color="primary" size="small">
            <Button onClick={scrollToNow}>
              <Typography variant="button">{translate('mymove.dates.now')}</Typography>
            </Button>
          </ButtonGroup>
          <Box ml={1} display="flex">
            <ButtonGroup color="primary" size="small">
              <Button onClick={zoomToDayView}>
                <Typography variant="button">{translate('mymove.dates.day')}</Typography>
              </Button>
              <Button onClick={zoomToWeekView}>
                <Typography variant="button">{translate('mymove.dates.week')}</Typography>
              </Button>
              <Button onClick={zoomToMonthView}>
                <Typography variant="button">{translate('mymove.dates.month')}</Typography>
              </Button>
            </ButtonGroup>
          </Box>
          <Box ml={1} display="flex">
            <ButtonGroup color="primary" size="small">
              <LargeTooltip title="mymove.scheduler.zoomOut">
                <Button
                  disabled={schedulerRef.current?.schedulerInstance?.viewPreset?.id === weekAndMonthCustomName}
                  onClick={zoomOut}
                >
                  <ZoomOutIcon />
                </Button>
              </LargeTooltip>
              <LargeTooltip title="mymove.scheduler.zoomIn">
                <Button
                  disabled={schedulerRef.current?.schedulerInstance?.viewPreset?.id === hourAndDayCustomName}
                  onClick={zoomIn}
                >
                  <ZoomInIcon />
                </Button>
              </LargeTooltip>
            </ButtonGroup>
          </Box>
          <Box ml={1} display="flex">
            <ButtonGroup color="primary" size="small">
              <LargeTooltip title="mymove.scheduler.navigateLeft">
                <Button onClick={() => schedulerRef.current.schedulerInstance.shiftPrevious()}>
                  <PreviousIcon />
                </Button>
              </LargeTooltip>
              <LargeTooltip title="mymove.scheduler.navigateRight">
                <Button onClick={() => schedulerRef.current.schedulerInstance.shiftNext()}>
                  <NextIcon />
                </Button>
              </LargeTooltip>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <div onContextMenu={onSchedulerContainerContextMenu}>
        <BryntumScheduler
          // Config
          allowOverlap={false}
          autoHeight
          barMargin={2}
          columns={columns}
          createEventOnDblClick={false}
          {...dates}
          enableUndoRedoKeys={false}
          eventRenderer={eventRenderer}
          events={events}
          eventStore={eventStore}
          listeners={listeners}
          multiEventSelect={false}
          onBeforeEventDelete={onBeforeEventDelete}
          presets={defaultPresets}
          ref={schedulerRef}
          resources={resources}
          rowHeight={60}
          snap
          useInitialAnimation={false}
          weekStartDay={1}
          zoomOnMouseWheel={false}
          zoomOnTimeAxisDoubleClick={false}
          // Features
          cellEditFeature={false}
          cellMenuFeature={cellMenu}
          eventCopyPasteFeature={false}
          eventDragFeature={eventDrag}
          eventMenuFeature={eventMenu}
          eventResizeFeature={eventResize}
          eventTooltipFeature={eventTooltip ?? false}
          groupFeature={hubs ? 'hub' : false}
          regionResizeFeature={false}
          resourceTimeRanges={resourceTimeRanges}
          resourceTimeRangesFeature
          scheduleMenuFeature={false}
          stripeFeature
          timeAxisHeaderMenuFeature={false}
          timeRangesFeature={{ showCurrentTimeLine: true }}
        />
      </div>
      {createBookingDialog}
      {createVehicleUnavailabilityDialog}
      <Dialog
        open={bookingOrVehicleUnavailabilityCreationDialogState.isOpen}
        onClose={closeBookingOrVehicleUnavailabilityCreationDialogIfNeeded}
        sx={{ '& .MuiDialog-paper': { flex: 'unset' } }}
      >
        <Box p={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button fullWidth variant="outlined" onClick={handleOpenCreateBookingDialog}>
                {translate('resources.bookings.forms.create.pageTitle')}
              </Button>
            </Grid>
            <Grid item>
              <Button fullWidth variant="outlined" onClick={handleOpenCreateVehicleUnavailabilityDialog}>
                {translate('resources.maintenances.forms.create.pageTitle')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      {editBookingDialog}
      {editVehicleUnavailabilityDialog}
      <Confirm
        cancel="mymove.notNow"
        content={`resources.bookings.actions.${bookingsActionsConfirmState.values.actionName}.confirmContent`}
        isOpen={bookingsActionsConfirmState.isOpen}
        loading={isRunningBookingAction}
        onClose={closeBookingsActionsConfirm}
        onConfirm={runBookingAction}
        sx={{ '& .MuiDialog-paper': { maxWidth: '450px' } }}
        title={bookingsActionsConfirmState.values.actionTitle}
      />
      <Confirm
        content="ra.message.delete_content"
        isOpen={deleteVehicleUnavailabilityConfirmState.isOpen}
        loading={isDeletingVehicleUnavailability}
        onClose={closeDeleteVehicleUnavailabilityConfirm}
        onConfirm={() => deleteVehicleUnavailability()}
        sx={{ '& .MuiDialog-paper': { flex: 'none' } }}
        title="resources.maintenances.actions.delete.confirmTitle"
      />
    </>
  )
}

export default Scheduler
